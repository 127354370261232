import React from "react"
import * as S from "./releases-slider.styles.jsx"
import { useTheme } from "@mui/material"
import BookCard from "../../components/book-card/book-card.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomSlider from "../../components/custom-slider/custom-slider.component"
import CustomButton from "../../components/custom-button/custom-button.component"

const ReleasesSlider = ({ releases, title }) => {
  const theme = useTheme()
  if (!releases) return null

  return (
    <SectionWrapper contained bg="gray">
      <S.TopContainer>
        {title && (
          <S.Title fontSize={1.5} color={theme.palette.text.primary} ls={1.88}>
            {title}
          </S.Title>
        )}
        {/*<CustomButton href="/radius-book-group-titles/">See More</CustomButton>*/}
      </S.TopContainer>
      <CustomSlider
        slidesToShow={6}
        breakpoints={[
          {
            breakpoint: 1330,
            settings: {
              dots: true,
              arrows: false,
            },
          },
          {
            breakpoint: 768,
            settings: {
              centerMode: false,
              slidesToScroll: 1,
              slidesToShow: 4,
              dots: true,
              arrows: false,
            },
          },
          {
            breakpoint: 425,
            settings: {
              dots: true,
              arrows: false,
              centerMode: false,
              slidesToScroll: 1,
              slidesToShow: 2,
              rows: 2,
            },
          },
        ]}
        infinite={false}
        style={{ margin: "0 -0.6em" }}
      >
        {releases.map(release => {
          const authorName =
            release?.book?.booksFields?.authorRepeater?.length > 0
              ? release?.book?.booksFields?.authorRepeater[0]?.author?.title
              : null
          return (
            <BookCard
              bookCover={
                release.book &&
                release.book.featuredImage &&
                release.book.featuredImage.node
              }
              link={`/book/${release.book && release.book.slug}`}
              authorName={authorName}
            />
          )
        })}
      </CustomSlider>
    </SectionWrapper>
  )
}
export default ReleasesSlider

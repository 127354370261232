import React from "react"
import * as S from "./author-slider.styles.jsx"
import { useTheme } from "@mui/material"
import AuthorCard from "./../../components/author-card/author-card.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomSlider from "../../components/custom-slider/custom-slider.component"
import CustomButton from "../../components/custom-button/custom-button.component"

const AuthorSlider = ({ authors, title, button }) => {
  const theme = useTheme()

  if (!authors) return null

  return (
    <SectionWrapper bg="gray" contained>
      <S.TopContainer>
        {title && (
          <S.Title fontSize={2} color={theme.palette.text.primary} ls={1.88}>
            {title}
          </S.Title>
        )}
        {button && (
          <CustomButton href={button?.url}>{button?.title}</CustomButton>
        )}
      </S.TopContainer>
      <CustomSlider
        style={{ margin: "0 -1em" }}
        slidesToShow={4}
        breakpoints={[
          {
            breakpoint: 1330,
            settings: {
              dots: true,
              arrows: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              dots: true,
              centerMode: false,
              slidesToScroll: 1,
              slidesToShow: 2,
            },
          },
        ]}
        infinite={false}
      >
        {authors.map(author => (
          <AuthorCard
            slug={author?.book?.uri}
            homeImage={author?.author?.authorFields?.homeImage}
            authorImage={author?.author?.featuredImage?.node}
            authorName={author?.author?.title}
            role={author?.author?.authorFields?.heroTagName}
          />
        ))}
      </CustomSlider>
    </SectionWrapper>
  )
}
export default AuthorSlider

import styled from "styled-components"
import { Typography } from "@mui/material"
import FluidImage from "../fluid-image/fluid-image"
import LineCover from "../../images/light.png"

export const CardContainer = styled.div`
  cursor: pointer;
  ${({ spacing }) => (spacing ? ` padding: 1.2em 0.6em;` : ``)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin: 10px 0.5rem 0 0.5rem;
  .bookCover {
    transition: all 0.3s ease;
  }

  &:hover {
    .bookCover {
      -webkit-transform: translate(0, -10px);
      -moz-transform: translate(0, -10px);
      -ms-transform: translate(0, -10px);
      -o-transform: translate(0, -10px);
      transform: translate(0, -10px);
      box-shadow: rgba(0, 0, 0, 0.2) 0 0.75rem 0.75rem;
    }
  }
`

export const BookCover = styled(FluidImage)`
  border-radius: 5px;
  background: linear-gradient(135deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  position: relative;
  //&:before {
  //  content: "";
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  left: 0;
  //  top: 0;
  //  display: block;
  //  background: linear-gradient(
  //    135deg,
  //    #ffffff 0%,
  //    rgba(255, 255, 255, 0) 100%
  //  );
  //  box-shadow: 16px 25px 30px 0 rgba(0, 0, 0, 0.19);
  //}

  &:after {
    content: "";
    position: absolute;
    background: url(${LineCover}) no-repeat center center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

export const AuthorContainer = styled.div`
  :not(&.home-card) {
    min-height: 135px;
    margin-bottom: 2em;
  }
  &.home-card p {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  }
`

export const AuthorImageContainer = styled.div`
  width: 35px;
  height: 35px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 0.5em;
`

export const AuthorImage = styled(FluidImage)`
  //margin: 0 auto;
  //margin-left: 20%; //centers the image
  height: 100%;
  width: auto;
`

export const AuthorName = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 20px;
  margin-top: 0.5em;
`
export const Role = styled(Typography)`
  opacity: 0.5;
  color: #0f1418;
  font-size: 14px;
  letter-spacing: 0.88px;
  line-height: 16px;
  margin-top: 0.5em;
`

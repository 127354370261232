import styled from "styled-components"
import Container from "@mui/material/Container"
import CustomButton from "../../components/custom-button/custom-button.component"

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.palette.primary.light};
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(60)};
  font-weight: 400;
  letter-spacing: 3.75px;
  line-height: 69px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    letter-spacing: 3.19px;
    line-height: 57px;
    font-size: ${({ theme }) => theme.typography.pxToRem(51)};
  }
`
export const TitleContainer = styled.div`
  margin-bottom: 2em;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 240px;
    margin-bottom: 0;
  }
`
export const CustomContainer = styled(Container)`
  margin-top: 6rem;
  padding-bottom: 6rem;
  border-bottom: rgb(216, 216, 216, 0.2) 1px solid;
`

export const Content = styled.div`
  margin-top: 0.5rem;
  p,
  li {
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    line-height: 29px;
  }
  h3 {
    color: ${({ theme }) => theme.palette.text.tertiary};
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    line-height: 29px;
    font-weight: normal;
  }
  ul {
    column-count: 2;
    column-gap: 20px; /* Adjust the gap between columns */
    list-style-type: none; /* Remove bullet points if needed */
    padding: 0;
  }
`
export const ConnectButton = styled(CustomButton)`
  margin-top: 1.5rem;
`

import React from "react"
import * as S from "./title-column.styles.jsx"
import Grid from "@mui/material/Grid"
import CustomButton from "../../components/custom-button/custom-button.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { graphql, useStaticQuery } from "gatsby"
import { openCalendlyWidget, removeHTML, textEllipsis } from "../../utils"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import { CalendarIcon } from "./title-column.styles.jsx"

const TitleColumn = ({
  leftTitle,
  rightText,
  button,
  flipCols,
  background,
  showCalendarButton,
}) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }, limit: 1) {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  `)
  const parse = require("html-react-parser")
  const title = staticQuery.allWpPost?.edges[0]?.node?.title
  const latestNews = staticQuery.allWpPost?.edges[0]?.node?.content
  const description = rightText ?? latestNews
  if (!description) return null
  return (
    <SectionWrapper bg={background} contained maxWidth="md">
      <S.CustomGrid flipCols={flipCols} container spacing={2}>
        <Grid item xs={12} sm={5}>
          <S.Item>
            <S.Title fontSize={3} className="title">
              {leftTitle}
            </S.Title>
          </S.Item>
        </Grid>
        <Grid item xs={12} sm={7}>
          <S.Item>
            {title && !rightText && (
              <S.Content fontSize={1} mb={0.5} style={{ fontWeight: "bold" }}>
                {title}
              </S.Content>
            )}
            {description && (
              <S.Content className={!rightText ? "news" : ""} fontSize={1}>
                {parse(description)}
              </S.Content>
            )}
          </S.Item>
          <div>
            {!showCalendarButton ? (
              <CustomButton href={button && button.url}>
                {button && button.title}
              </CustomButton>
            ) : (
              <CustomButton onClick={openCalendlyWidget}>
                Connect with Us <S.CalendarIcon />
              </CustomButton>
            )}
          </div>
        </Grid>
      </S.CustomGrid>
    </SectionWrapper>
  )
}
export default TitleColumn

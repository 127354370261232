import * as S from "../AboutWhy/about-why.styles"
import React from "react"
import Grid from "@mui/material/Grid"
import parse from "html-react-parser"
import { openCalendlyWidget } from "../../utils"

const AboutWhy = ({ leftTitle, content }) => {
  return (
    <S.Wrapper>
      <S.CustomContainer maxWidth="md">
        <Grid container>
          <Grid item xs={12} md={4}>
            <S.TitleContainer>
              <S.Title>{leftTitle}</S.Title>
            </S.TitleContainer>
          </Grid>
          <Grid item xs={12} md={8}>
            {content && <S.Content>{parse(content)}</S.Content>}
            <S.ConnectButton
              className="lightButton"
              onClick={openCalendlyWidget}
            >
              Connect whit us
            </S.ConnectButton>
          </Grid>
        </Grid>
      </S.CustomContainer>
    </S.Wrapper>
  )
}
export default AboutWhy

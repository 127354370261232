import React from "react"
import * as S from "./image-text-section.styles"
import FluidImage from "../../components/fluid-image/fluid-image"
import Grid from "@mui/material/Grid"
import CustomTypography from "../../components/custom-typography/custom-typography.component"
import CustomButton from "../../components/custom-button/custom-button.component"
import { Container, useMediaQuery, useTheme } from "@mui/material"
import { openCalendlyWidget } from "../../utils"

const ImageTextSection = ({ image, title, tagText, button }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <S.Wrapper>
      <Container>
        <Grid container spacing={isDesktop ? 6 : 2}>
          <S.ImageContainer item xs={12} sm={7}>
            <FluidImage img={image} className="img" />
          </S.ImageContainer>
          <S.ItemGrid item xs={12} sm={5}>
            <div className="items-container">
              <S.Item>
                <CustomTypography style={{ letterSpacing: 3.33 }} fontSize={1}>
                  {tagText}
                </CustomTypography>
              </S.Item>
              <S.Item>
                <S.Title fontSize={3}>{title}</S.Title>
              </S.Item>
              {button && (
                <S.Item>
                  <CustomButton
                    onClick={openCalendlyWidget}
                    className="lightButton"
                  >
                    Connect with us
                  </CustomButton>
                </S.Item>
              )}
            </div>
          </S.ItemGrid>
        </Grid>
      </Container>
    </S.Wrapper>
  )
}
export default ImageTextSection
